





















































































































import { Component, Vue } from 'vue-property-decorator';

// 0. Сначала вкладку надо создать
// 1. здесь импортируем вкладку
import CCalendarTest from './c-calendar-test';
import CDatePickerTest from './c-date-picker-test';
import CLoadingTest from './c-loading-test';
import CModalTest from './c-modal-test';
import CPaginationTest from './c-pagination-test';
import CSelectTest from './c-select-test';
import VuexTest from './c-vuex-test/VuexTest.vue';


const localStorageKey = 'ui-playground/tab';


@Component({
    components: {
        // 2. Здесь указываем, под каким имененем класс вкладки будет доступен в разметке
        'calendar': CCalendarTest,
        'date-picker': CDatePickerTest,
        'loading': CLoadingTest,
        'modal': CModalTest,
        'pagination': CPaginationTest,
        'select-test': CSelectTest,
        'vuex': VuexTest
    }
})
export default class UiPlayground extends Vue {
    private listener!: () => void;
    private windowWidth = 0;
    private windowHeight = 0;
    private divWidth = 0;
    private divHeight = 0;
    private htmlWidth = 0;
    private htmlHeight = 0;

    private created() {
        this.listener = () => {
            this.windowWidth = window.innerWidth;
            this.windowHeight = window.innerHeight;

            const html = document.querySelector('html');
            if (html instanceof HTMLHtmlElement) {
                this.htmlWidth = html.offsetWidth;
                this.htmlHeight = html.offsetHeight;
            }

            const div = document.querySelector('#app');
            if (div instanceof HTMLDivElement) {
                this.divWidth = div.offsetWidth;
                this.divHeight = div.offsetHeight;
            }
        };
        window.addEventListener('resize', this.listener);
        this.listener();

        this.$watch('tab', this.tabChanged);

        const tab = localStorage.getItem(localStorageKey);
        if ((typeof tab === 'string') && (this.tab !== tab)) {
            this.tab = tab;
        }
    }

    private beforeDestroy() {
        window.removeEventListener('resize', this.listener);
    }


    private tab = 'calendar';

    private tabChanged() {
        localStorage.setItem(localStorageKey, this.tab);
    }
}

// Для следующих примеров можно использовать разметки с комментариями "Calendar test"
// 3. Внутри div'а с 'type="tab-buttons"' надо добавить кнопку вкладки (примерно также, как и существующие кнопки)
// 4. Внутри div'а с 'type="tab-content"' надо добавить отображение вкладки (по образцу существующих вкладок)
