import { Module } from 'vuex';
import store from '@/services/store';

// интерфейс описывает содержимое state
export interface IMod {
    str: string;
}

const mod: Module<IMod, any> = {
    namespaced: true, // чтобы модуль был доступен по своему имени
    state: { // данные хранилища
        str: 'mod registered'
    },
    getters: { // геттеры принимает один параметр state текущего модуля
        str2(state): string {
            return state.str + '!';
        }
    },
    mutations: { // изменение хранилища
        /*  имя мутации используется в комите см. "store.commit" */
        strChange(state, str: string) {// мутации принимают только 2 объекта, 1й всегда state текущего модуля, 2й - то что передаётся при вызове store.commit
            state.str = str;
        }
    }
};
store.registerModule('vuexTest', mod); // регистрация модуля
export default mod;
const state2 = mod.state as IMod;
export const accessMod = { // объект для упрощения работы с модулем
    get str(): string {
        return state2.str;
    },
    set str(str: string) {
        store.commit('vuexTest/strChange', str); // 'имя модуля/имя мутации', значение
    },
    get str2(): string { // использование геттера
        return store.getters['vuexTest/str2'];
    }
};