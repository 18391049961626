






import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import mod, { IMod, accessMod } from './vuex-module';

@Component
export default class CAnotherVuex extends Vue {
    private get getMod(): string {
        return accessMod.str;
    }
    private get getMod2():string{
        return accessMod.str2;
    }
    private changeStr() {
        accessMod.str = 'совсем другое значение';
    }
}
