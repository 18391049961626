








import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import mod, { IMod, accessMod } from './vuex-module';
import CAnotherVuex from './c-another-vuex.vue';

@Component({
    components: {
        'c-anoth': CAnotherVuex
    }
})
export default class VuexTest extends Vue {
    private get getMod(): string {
        return accessMod.str;
    }
    private changeStr() {
        accessMod.str = 'другое значение';
    }
}
