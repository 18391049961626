var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"internal-content ui-pg"},[_vm._v(" UI playground ( "+_vm._s(_vm.windowWidth)+" x "+_vm._s(_vm.windowHeight)+" // "+_vm._s(_vm.divWidth)+" x "+_vm._s(_vm.divHeight)+" // "+_vm._s(_vm.htmlWidth)+" x "+_vm._s(_vm.htmlHeight)+") "),_c('div',{style:({
            'position': 'fixed',
            'left': '0',
            'top': '0',
            'width': '2px',
            'height': (_vm.windowHeight - 5) + 'px',
            'background-color': 'black'
        })}),_c('div',{style:({
            'position': 'fixed',
            'left': '0',
            'top': '0',
            'width': (_vm.windowWidth - 5) + 'px',
            'height': '2px',
            'background-color': 'black'
        })}),_c('div',{attrs:{"type":"tab-buttons"}},[_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'calendar'},on:{"click":function($event){_vm.tab = 'calendar'}}},[_vm._v("Calendar")]),_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'date-picker'},on:{"click":function($event){_vm.tab = 'date-picker'}}},[_vm._v("Date picker")]),_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'loading'},on:{"click":function($event){_vm.tab = 'loading'}}},[_vm._v("Loading")]),_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'modal'},on:{"click":function($event){_vm.tab = 'modal'}}},[_vm._v("Modal")]),_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'pagination'},on:{"click":function($event){_vm.tab = 'pagination'}}},[_vm._v("Pagination")]),_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'select'},on:{"click":function($event){_vm.tab = 'select'}}},[_vm._v("Select")]),_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'button'},on:{"click":function($event){_vm.tab = 'button'}}},[_vm._v("Button")]),_c('button',{staticClass:"btn-button",attrs:{"disabled":_vm.tab === 'vuex'},on:{"click":function($event){_vm.tab = 'vuex'}}},[_vm._v("Vuex")])]),_c('hr'),_c('div',{attrs:{"type":"tab-content"}},[(_vm.tab === 'calendar')?_c('calendar'):_vm._e(),(_vm.tab === 'date-picker')?_c('date-picker'):_vm._e(),(_vm.tab === 'loading')?_c('loading'):_vm._e(),(_vm.tab === 'modal')?_c('modal'):_vm._e(),(_vm.tab === 'pagination')?_c('pagination'):_vm._e(),(_vm.tab === 'select')?_c('select-test'):_vm._e(),(_vm.tab === 'button')?_c('button',{staticClass:"btn-icon"},[_c('c-icon',{attrs:{"icon":"gesture-tap-button"}})],1):_vm._e(),(_vm.tab === 'button')?_c('button',{staticClass:"btn-link"},[_c('a',{attrs:{"href":"#"}},[_vm._v("Link")])]):_vm._e(),(_vm.tab === 'vuex')?_c('vuex'):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }